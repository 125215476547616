import React, { useState } from 'react';
import { Modal, DatePicker, Select, Button, Form } from 'antd';
import { options } from '../../../common/constant';
import { API } from '../../../apis/apis';
import { Notify, errMsgNotify } from '../../../common/util';
import ConfirmModal from '../../../components/messsageModal/ConfirmModal';

const { RangePicker } = DatePicker;
const { Option } = Select;
const DeleteProjectData = ({ isDeleteModalVisible, selectedProject, onCancel }) => {
    const api = API()
    const [form] = Form.useForm();
    const [selectedType, setSelectedType] = useState(undefined);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);


    const handleTypeChange = value => {
        setSelectedType(value);
    };
    const handleSubmit = () => {
        form
            .validateFields()
            .then((values) => {
                setConfirmModalOpen(true)
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
            });
    };
    const handleDeleteClick = async ({ type, dateRange }) => {
        try {
            const fromDate = dateRange[0].format("YYYY-MM-DD")
            const toDate = dateRange[1].format("YYYY-MM-DD")
            const formData = {
                "project_id": selectedProject.id,
                "start_date": fromDate,
                "end_date": toDate,
                "file_type": type
            }
            await api.DeleteData(formData)
            Notify("success", "Deleted successfully")
        } catch (error) {
            console.error(error);
            error?.response?.data && errMsgNotify(error.response.data)
        }
    }
    const handleConfrimation = () => {
        handleDeleteClick(form.getFieldsValue())
        form.resetFields();
        setConfirmModalOpen(false)
    }
    return (
        <>
            <Modal title="Delete Project's Media"
                open={isDeleteModalVisible}
                onOk={() => { }}
                onCancel={onCancel}
                footer={[
                    <Button className="add-user" onClick={handleSubmit}>Delete</Button>
                ]}
                wrapClassName="custom-modal-footer"
            >
                <Form form={form} layout="vertical" >
                    <Form.Item
                        name="dateRange"
                        label="Select Date Range"
                        rules={[{ required: true, message: 'Please input the Date Range!' }]}
                    >
                        <RangePicker />
                    </Form.Item>

                    <Form.Item
                        name="type"
                        label="Select Type"
                        rules={[{ required: true, message: 'Please select the Type!' }]}
                    >
                        <Select
                            placeholder="Select type"
                            style={{ width: '100%', margin: '10px 0' }}
                            value={selectedType}
                            onChange={handleTypeChange}
                        >
                            {options.map((option) => (
                                <Option key={option.key} value={option.key}>
                                    {option.value}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
            {confirmModalOpen ? <ConfirmModal
                msg={"Are you sure you would like to delete?"} onOk={handleConfrimation} onClose={() => setConfirmModalOpen(false)} /> : null}
        </>
    );
}
export default DeleteProjectData