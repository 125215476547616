import { SearchOutlined } from '@ant-design/icons'
import { Button, Input } from 'antd'
import React, { useState } from 'react'
import { API } from '../../../apis/apis'
import { Notify, errMsgNotify } from '../../../common/util'
import CreateProjectModal from '../CreateProject'
import DeleteUserDataModal from '../DeleteUserData/DeleteUserDataModal'

const Filter = ({ fetchData }) => {
  const api = API()
  const [open, setOpen] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isDeleteUserModalVisible, setIsDeleteUserModalVisible] = useState(false);

  const handleCreate = (payload) => {
    const { name, images,video_quality } = payload
    const formData = new FormData();
    formData.append("name", name);
    formData.append("video_quality", video_quality);
    if (images) {
      images.forEach(file => {
        if (file.originFileObj) {
          formData.append('images', file.originFileObj);
        }
      });
    }
    const createProject = async () => {
      setIsSubmitting(true)
      try {
        await api.createProject(formData)
        Notify("success", "Created Successfully!")
        setOpen(false)
        setIsSubmitting(false)
        fetchData()
      } catch (error) {
        setIsSubmitting(false)
        errMsgNotify(error.response.data)
      }

    }
    createProject()
  }
  return (
    <div className='d-flex justify-content-between mb-3'>
      <Input className="w-25" style={{ height: "40px" }} prefix={<SearchOutlined />} placeholder='Search' />
      <span>
      <Button className="del-user" onClick={() => setIsDeleteUserModalVisible(true)}>Delete User Data</Button>
      <Button className="add-user m-1"  onClick={() => setOpen(true)}>Create New Project</Button>
      </span>
      {open ? <CreateProjectModal isSubmitting={isSubmitting} open={open} onCreate={handleCreate} onCancel={() => setOpen(false)} /> : null}
      {isDeleteUserModalVisible?<DeleteUserDataModal isSubmitting={isSubmitting} open={isDeleteUserModalVisible}   onCancel={() => { setIsDeleteUserModalVisible(false);  }} />:null}
    </div>
  )
}

export default Filter
