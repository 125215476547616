export const menuMaster=[
    {
        name:"Admins",
        route:"/owner/users"
    },
    
]
export const menuAdmin=[
    {
        name:"Projects",
        route:"/admin/projects"
    },
]
