import { Navigate } from "react-router-dom";
import { useUIContext } from "../../context";


function NotRequireAuth ({ children ,forMasterAdmin }) {
    const addOrderUIContext = useUIContext();
    const {user,owner}=addOrderUIContext
   console.log(forMasterAdmin);
   debugger
    if(forMasterAdmin){
        if (!!owner.access) {
            return <Navigate to="/owner/users" replace />;
        }
    }
    else if (!!user.access) {
        return <Navigate to="/admin/projects" replace />;
    }
    return children
    
}

export default NotRequireAuth