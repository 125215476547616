import { useBaseService } from './baseService';

export function API() {
    const httpService = useBaseService();

    const login = async (params) => {
        try {
            const data = await httpService.postLogin("api/users/me/token/", params);
            return data
        } catch (error) {
            throw error;
        }
    }
    const getUserData = async (params) => {
        try {
            const data = await httpService.get("api/users/me/");
            return data
        } catch (error) {
            throw error;
        }
    };
    const getUsersData = async (params) => {
        try {
            const data = await httpService.get("api/users/");
            return data
        } catch (error) {
            throw error;
        }
    };
    const createUser = async (params) => {
        try {
            const data = await httpService.post("api/users/", params);
            return data
        } catch (error) {
            throw error;
        }
    }
    const editUser = async (id,params) => {
        try {
            const data = await httpService.patch(`api/users/${id}/`, params);
            return data
        } catch (error) {
            throw error;
        }
    }
    const delUser = async (id) => {
        try {
            const data = await httpService.del(`api/users/`,id);
            return data
        } catch (error) {
            throw error;
        }
    }
    const getProjectsData = async (params) => {
        try {
            const data = await httpService.get("api/projects/");
            return data
        } catch (error) {
            throw error;
        }
    };
    const getProjectsDataById = async (params) => {
        try {
            const data = await httpService.get(`api/projects/${params}/`);
            return data
        } catch (error) {
            throw error;
        }
    };
    const deleteProject = async (params) => {
        try {
            const data = await httpService.del("api/projects/", params);
            return data
        } catch (error) {
            throw error;
        }
    }
    const createProject = async (params) => {
        try {
            const data = await httpService.post("api/projects/", params);
            return data
        } catch (error) {
            throw error;
        }
    }
    const delImage = async (id,params) => {
        try {
            const data = await httpService.post(`api/projects/${id}/rm_image`, params);
            return data
        } catch (error) {
            throw error;
        }
    }
    const editProject = async (id,params) => {
        try {
            const data = await httpService.patch(`api/projects/${id}/`, params);
            return data
        } catch (error) {
            throw error;
        }
    }
    const saveDrawnImages = async (params) => {
        try {
            const data = await httpService.post("api/survey/base64/", params);
            return data
        } catch (error) {
            throw error;
        }
    }
    const exportData = async (params) => {
        try {
            const data = await httpService.post("api/projects/export/", params);
            return data
        } catch (error) {
            throw error;
        }
    }
    const DeleteData = async (params) => {
        try {
            const data = await httpService.post("api/projects/delete/", params);
            return data
        } catch (error) {
            throw error;
        }
    }
    const deleteData = async (params) => {
        try {
            const data = await httpService.post("api/survey/export-project/", params);
            return data
        } catch (error) {
            return error
        }
    }
    const uploadRecordedFile = async (params) => {
        try {
            const data = await httpService.post("api/survey/", params);
            return data
        } catch (error) {
            throw error;
        }
    }
    const deleteUserData = async (params) => {
        try {
            const data = await httpService.post("api/survey/delete-user/", params);
            return data
        } catch (error) {
            console.log("Error", error)
            return error;
        }
    }
    const deleteUserAllData=async (params)=>{
        try {
            const data = await httpService.post("api/surveys/user/delete/", params);
            return data
        } catch (error) {
            console.log("Error", error)
            throw error;
        }
    }
    return {
        delImage,
        getUserData,
        getProjectsData,
        deleteProject,
        createProject,
        editProject,
        getProjectsDataById,
        saveDrawnImages,
        exportData,
        DeleteData,
        uploadRecordedFile,
        deleteData,
        deleteUserData,
        login,
        getUsersData,
        createUser,
        editUser,
        delUser,
        deleteUserAllData
    }
}
