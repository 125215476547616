import React, { useEffect, useState } from 'react'
import Filter from './filter'
import Listing from './listing'
import { API } from '../../../apis/apis'

const ProjectListing = () => {
  const api=API()
  const [tableData, setTableData] = useState([]);
  const fetchData = async () => {
     api.getProjectsData().then((data)=>
    setTableData(data?.results || [])).catch((err)=>{
      console.log("error")
    })
  }
  useEffect(() => {
    if (!tableData?.length) {
      fetchData()
    }
  }, []);
 
  return (
    <div className=''>
        <Filter fetchData={fetchData} />
      <div>Projects</div>
      <Listing fetchData={fetchData} tableData={tableData}/>
    </div>
  )
}

export default ProjectListing
