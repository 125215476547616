import React, { useState } from 'react';
import { Modal, DatePicker, Select, Button, Form } from 'antd';
import { options } from '../../../common/constant';
import { API } from '../../../apis/apis';
import { errMsgNotify } from '../../../common/util';

const { RangePicker } = DatePicker;
const { Option } = Select;

const ExportProjectsModal = ({isExportModalVisible,selectedProject,onCancel}) => {
    const api=API()
    const [form] = Form.useForm();
  const [selectedType, setSelectedType] = useState(undefined);

  const handleTypeChange = value => {
    setSelectedType(value);
  };
  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        form.resetFields();
        handleExportClick(values);
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };
  const handleExportClick = async ({type,dateRange}) => {
    let res
    try {
    const fromDate= dateRange[0].format("YYYY-MM-DD")
    const toDate= dateRange[1].format("YYYY-MM-DD")
    const formData = new FormData();
    formData.append('start_date', fromDate);
    formData.append("end_date", toDate);
    formData.append("file_type", type);
    formData.append("project_id", selectedProject.id);
     res = await api.exportData(formData)
    } catch (error) {
        error?.response?.data && errMsgNotify(error.response.data)
        return
    }
    const downloadURL=(`${process.env?.REACT_APP_BASE_URL || document.location.origin}${res.download_url}`)
    const link = document.createElement('a');
    link.href = downloadURL;
    link.download = `${selectedProject.name||'export'}.zip`;
    link.click();
}

  return (
    <>
      <Modal title="Export Your Projects"
       open={isExportModalVisible} 
       onOk={()=>{}} 
       onCancel={onCancel}
       footer={[
        <Button className="add-user" onClick={handleSubmit}>Export</Button>      
      ]}
       wrapClassName="custom-modal-footer"
      >
       <Form form={form} layout="vertical" >
      <Form.Item
        name="dateRange"
        label="Select Date Range"
        rules={[{required:true, message: 'Please input the Date Range!' }]}
      >
        <RangePicker  />
      </Form.Item>

      <Form.Item
        name="type"
        label="Select Type"
        rules={[{required:true, message: 'Please select the Type!' }]}
      >
        <Select 
          placeholder="Select type" 
          style={{ width: '100%', margin: '10px 0' }} 
          value={selectedType} 
          onChange={handleTypeChange}
        >
          {options.map((option) => (
            <Option key={option.key} value={option.key}>
              {option.value}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </Form>
      </Modal>
    </>
  );
};

export default ExportProjectsModal;
