import { Route, Routes } from 'react-router-dom';
import SideBar from '../../components/sideBar/SideBar';
import ProjectListing from './ProjectListing';
import { API } from '../../apis/apis';
import { useUIContext } from '../../context';
import { useEffect } from 'react';



function Admin() {
  const api = API()
  const addOrderUIContext = useUIContext();
    const { setUser } = addOrderUIContext
  useEffect(() => {
    api.getUserData().then((res) => {
      setUser((prev)=>({...prev,userData:res}))
    }).catch((err) => console.log(err))
  }, [])
  return (
    <div className='mt-1'>
      <SideBar/>
      <div className='main-content'>
      <Routes>
      <Route path="/projects" element={<ProjectListing />} />
      <Route path="*" element={<h1>Page not found</h1>} />
      </Routes>
      </div>
    </div>
  );
}


export default Admin
