import './App.css'
import { UIProvider } from './context';
import IRoutes from './routes/routes';


function App() {
  return (
    <UIProvider>
    <IRoutes />
    </UIProvider>
  );
}

export default App;
