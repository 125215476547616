import { Button, Form, Input, Modal, Spin, Switch } from 'antd';
import { isEmptyObject } from '../../../common/util';
import { useState } from 'react';


const UserForm = ({ onFinish, confirmLoading, setOpen, open, editWith = {} }) => {
    const [form] = Form.useForm();
    const [isActive, setIsActive] = useState(editWith?.is_active || false)
    const validatePassword = (rule, value, callback) => {
        // Define a regular expression pattern for the password validation
        const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

        if (!value && requiredOnEdit()) {
            callback('Please input your Password!');
        } else if (!passwordPattern.test(value) && requiredOnEdit()) {
            callback('Password must be minimum eight characters, at least one uppercase letter, one lowercase letter, one number, and one special character.');
        } else {
            callback();
        }
    };
    const requiredOnEdit = () => editWith?.id ? false : true
    return (
        <>
            <Modal
                title={!isEmptyObject(editWith) ? "Edit User" : "Add New User"}
                centered
                open={open}
                onOk={() => setOpen(false)}
                onCancel={() => setOpen(false)}
                width={500}
                footer={null}
            >
                <Spin spinning={confirmLoading}>
                    <Form
                        name="User-form"
                        className="login-form"
                        initialValues={{ is_active: false, ...editWith }}
                        onFinish={(values) => onFinish({ ...values, is_active: isActive })}
                        autoComplete="off"
                        form={form}
                        layout="vertical" // Set the layout to vertical
                    >
                        <Form.Item
                            name="username"
                            label="Username"
                            rules={[{ required: true, message: 'Please input your Username!' }]}
                        >
                            <Input autoComplete="off" placeholder="Username" />
                        </Form.Item>
                        <Form.Item
                            name="admin_key"
                            label="Admin Key"
                            rules={[{ required: true, message: 'Please input your Admin Key!' }]}
                        >
                            <Input autoComplete='off' placeholder="Admin Key" />
                        </Form.Item>
                        <Form.Item
                            name="first_name"
                            label="First Name"
                            rules={[{ required: false, message: 'Please input your Firstname!' }]}
                        >
                            <Input placeholder="First name" />
                        </Form.Item>
                        <Form.Item
                            name="last_name"
                            label="Last Name"
                            rules={[{ required: false, message: 'Please input your Lastname!' }]}
                        >
                            <Input placeholder="Last name" />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            label="Password"
                            rules={[{ required: requiredOnEdit(), validator: validatePassword }]}
                        >
                            <Input.Password placeholder='Password' />
                        </Form.Item>
                        <Form.Item
                            name="confirm"
                            label="Confirm Password"
                            dependencies={['password']}
                            hasFeedback
                            rules={[
                                {
                                    required: requiredOnEdit(),
                                    message: 'Please confirm your password!',
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('The new password that you entered do not match!'));
                                    },
                                }),
                            ]}
                        >
                            <Input.Password
                                autoComplete="new-password"
                                placeholder='Confirm password'
                            />
                        </Form.Item>
                        <div className='d-flex justify-content-between'><label>Active</label >
                            <Switch checked={isActive} onChange={(e) => { setIsActive(e); form.setFieldValue("is_active", e) }} />
                        </div>
                        <div className='d-flex justify-content-center'>
                            <Button htmlType="submit" style={{ color: "white" }} className="login-btn login-form-button w-25">
                                {!isEmptyObject(editWith) ? "Edit User" : "Add User"}
                            </Button>
                            <Button onClick={() => setOpen(false)} style={{ borderRadius: "16px" }} className="mx-1 w-25">
                                Discard
                            </Button>
                        </div>
                    </Form>
                </Spin>
            </Modal>
        </>
    );
};


export default UserForm
