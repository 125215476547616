import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, Button, Upload, Spin, Select } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { VIDEO_QUALITY } from '../../../common/constant';

const CreateProjectModal = ({ open, onCreate, onCancel, isSubmitting, forEdit = {} }) => {
  const [form] = Form.useForm();
  const [deleted, setDeleted] = useState([])
  const [videoQuality,setVideoQuality]=useState(forEdit?.id?forEdit?.video_quality:1)
  const urlToFileList = (urls) => {
    return urls.map((url, index) => ({
      uid: index.toString(),
      name: `Image ${index + 1}`,
      status: 'done',
      url: process.env?.REACT_APP_BASE_URL || document.location.origin + url,
    }));
  };

  // Effect to set initial values for editing
  useEffect(() => {
    if (forEdit?.id) {
      form.setFieldsValue({
        ...forEdit,
        images: forEdit.image_urls ? urlToFileList(forEdit.image_urls) : [],
      });
    }
  }, [forEdit, form]);

  const handleSubmit = (e) => {
    e.preventDefault()
    form.submit()
  };
  // The upload button
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <Modal
      title={forEdit?.id ? "Edit Project" : "Create New Project"}
      open={open}
      // onOk={handleSubmit}
      onCancel={onCancel}
      footer={[
        <Button key="submit" className="add-user" onClick={handleSubmit}>
          {!!forEdit?.id ? "Edit Project" : "Create Project"}
        </Button>,
        <Button key="back" style={{ height: '40px' }} onClick={onCancel}>
          Cancel
        </Button>,

      ]}
    >
      <Spin spinning={isSubmitting}>
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          onFinish={(values) => onCreate({ ...values,video_quality:videoQuality, deleted })}
          initialValues={{ ...forEdit }} // Set the initial values here
        >
          <Form.Item
            name="name"
            label="Write here your Project Name:"
            rules={[{ required: true, message: 'Please input the name of the project!' }]}
          >
            <Input placeholder='Project Name' />
          </Form.Item>
          <div>*Select the video quantity</div>
          <Select className='mb-2 w-100'
            placeholder='Video Quality' 
            value={videoQuality.toString()} 
            onChange={(e)=>{
              console.log(e);
              setVideoQuality(+e)
            }}
            options={Object.keys(VIDEO_QUALITY).map((quality)=>({value:""+quality,label:VIDEO_QUALITY[quality],key:""+quality}))}
            >
            </Select>
          <Form.Item
            name="images"
            label="Choose Images to upload:"
            valuePropName="fileList"
            rules={[{ required: true, message: 'Provide atleast one image!' }]}
            getValueFromEvent={({ file, fileList }) => {
              // Only show latest 6 images
              return fileList;
            }}
            onChange={(fileList) => {
              console.log(fileList);
              // Update the state with new fileList
            }}
            extra="Drag & drop images here or click to browse"
          >
            <Upload
              beforeUpload={() => false}
              listType="picture-card"
              multiple={true}
              className="upload-list-inline"
              showUploadList={{
                showPreviewIcon: false, // Hide preview icon
                showRemoveIcon: true,   // Show delete icon
                showDownloadIcon: false // Hide download icon if it's not necessary
              }}
              // Implement custom logic for file removal if necessary
              onRemove={(file) => {
                deleted.push(file)
                // You can update the state here if you're tracking uploaded files
              }}
            >
              {uploadButton}
            </Upload>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default CreateProjectModal;
