import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, Button, Upload, Spin } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Notify, errMsgNotify } from '../../../common/util';
import { API } from '../../../apis/apis';
import ConfirmModal from '../../../components/messsageModal/ConfirmModal';

const DeleteUserDataModal = ({ open, onCancel, }) => {
    const [form] = Form.useForm();
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);

    // Effect to set initial values for editing
    const handleSubmit = (e) => {
        e.preventDefault()
        debugger
        form.submit()
    };

    // The upload button
    const api = API()
    const handleConfrimation = () => {
        handleOnDelete(form.getFieldsValue())
        form.resetFields();
        setConfirmModalOpen(false)
    }
   
    const handleOnDelete = async (form) => {
        setIsSubmitting(true)
        try {
            await api.deleteUserAllData(form)
            setIsSubmitting(false)
            onCancel()
            Notify("success", "user deleted successfully")
        } catch (error) {
            setIsSubmitting(false)
            errMsgNotify(error.response.data)
        }
    }
    return (
        <>
        <Modal
            title={"Delete User All Data"}
            open={open}
            // onOk={handleSubmit}
            onCancel={onCancel}
            footer={[
                <Button key="submit" className="del-user" onClick={handleSubmit}>
                    {"DELETE"}
                </Button>,
                <Button key="back" style={{ height: '40px' }} onClick={onCancel}>
                    Cancel
                </Button>,

            ]}
        >
            <Spin spinning={isSubmitting}>
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                    onFinish={(values) =>   setConfirmModalOpen(true)}
                    initialValues={{}} // Set the initial values here
                >
                    <Form.Item
                        name="user_id"
                        label="User Id:"
                        rules={[{ required: true, message: 'Please input the User id!' }]}
                    >
                        <Input placeholder='User Id' />
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
        {confirmModalOpen ? <ConfirmModal
                msg={"Are you sure you would like to delete?"} onOk={handleConfrimation} onClose={() => setConfirmModalOpen(false)} /> : null}
        </>
    );
};

export default DeleteUserDataModal;
