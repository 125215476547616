import { Button, } from "antd";
import { useUIContext } from "../../context";
import { checkOwner, clearLocalToken } from "../../common/util";
import logo from "../../assets/img/A4.png"

const Header = () => {
    const addOrderUIContext = useUIContext();
    const { user, setUser, setOwner, owner
    } = addOrderUIContext || {}
    const { access, userData } = user
    const handleSignOut = () => {
        if (checkOwner()) {
            setOwner((prev) => ({ access: "" }))
            clearLocalToken("presistedOwner")
        } else {
            setUser((prev) => ({ access: "" }))
            clearLocalToken("presistedUser")
        }
    }
    return (
        <div className="app-header d-flex justify-content-between">
            <div className="label">
                <p className="topophi">
                    {/* <span className="text-wrapper">Topo</span>
                    <span className="span">phi</span> */}
                    <img alt="Topophi" src={logo} />
                </p>
            </div>
            {!!userData?.admin_key ? <span className="label admin-key mt-1"><span className="topophi" style={{ color: "black" }}>Admin key: {userData?.admin_key || ""}</span></span> : null}
            {(access && !checkOwner()) || (owner?.access && checkOwner()) ? <Button className="m-3" onClick={handleSignOut}>Sign out</Button> : null}
        </div>
    );
}

export default Header;

