import React, { createContext, useContext, useState } from "react";
import { jwtDecode } from "jwt-decode";

const UIContext = createContext();

export function useUIContext() {
  return useContext(UIContext);
}

export const UIConsumer = UIContext.Consumer; // TODO: Need to use proper consumer

export function UIProvider({  children }) {
 
  const [user, setUser] = useState(()=>{
    try {
     let obj= JSON.parse(localStorage.getItem("presistedUser"))
     const decoded = jwtDecode(obj.access);
     obj["is_MasterAdmin"]=decoded?.is_superadmin
     return obj
    } catch (error) {
      return {}
    }
  });
  const [owner, setOwner] = useState(()=>{
    try {
     let obj= JSON.parse(localStorage.getItem("presistedOwner"))
     const decoded = jwtDecode(obj.access);
     obj["is_MasterAdmin"]=decoded?.is_superadmin
     return obj
    } catch (error) {
      return {}
    }
  });
  const [openTaskCompltModal,setOpenTaskCompltModal]=useState(false)
  const [openConfirmationModal,setOpenConfirmationModal]=useState(false)
  const [TaskCompltModalMsg,setTaskCompltModalMsg]=useState(false)

  const [initialValues, setInitialValues] = useState({})
    console.log("user",user);
    console.log("user",owner);

  const value = {
    initialValues,
    setInitialValues,
    user,
    setUser,
    owner, 
    setOwner,
    openTaskCompltModal,
    setOpenTaskCompltModal,
    TaskCompltModalMsg,
    setTaskCompltModalMsg,
    openConfirmationModal,
    setOpenConfirmationModal
  };

  return <UIContext.Provider value={value}>{children}</UIContext.Provider>;
}
