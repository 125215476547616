// import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { useUIContext } from "../../context";

function RequireAuth({ children, forMasterAdmin = undefined }) {
    const addOrderUIContext = useUIContext();
    const { user,owner } = addOrderUIContext

    
    // const accessToken = useSelector(state=>state.user.accessToken)
    if ( document.location.href.includes("owner")) {
        const { access } = owner
        if (!access) {
            return <Navigate to="/owner" replace />;
        }
        return children
    }else{
        const { access } = user
        if (!access) {
            return <Navigate to="/" replace />;
        } else {
            return children
        }
    }
   

}

export default RequireAuth