import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import Login from '../page/landing/landing';
import Header from '../components/header/header';
// import Footer from '../components/footer/footer';
import Admin from '../page/admin';
// import Aadmin from '../page/admin/admin';

// import Interview from '../page/interview/interview';
import NotRequireAuth from '../page/auth/NotRequiredAuth';
import RequireAuth from '../page/auth/RequireAuth';
import Login from '../page/auth/Login';
import MasterAdmin from '../page/MasterAdmin'
import TaskCompletion from '../components/messsageModal/TaskCompletion';
import { useUIContext } from '../context';

const IRoutes = () => {
    const addOrderUIContext = useUIContext();
    const { openTaskCompltModal,
        TaskCompltModalMsg,
        setOpenTaskCompltModal,
         } = addOrderUIContext

    return (
        <>
            {openTaskCompltModal ? <TaskCompletion msg={TaskCompltModalMsg} onClose={() => setOpenTaskCompltModal(false)} /> : null}
            <div>
                <Header />
            </div>
            <Router>
                <Routes>
                    <Route
                        path="/"
                        element={
                            <NotRequireAuth>
                                <Login />
                            </NotRequireAuth>
                        } />
                        <Route
                        path="/owner"
                        element={
                            <NotRequireAuth forMasterAdmin={true}>
                                <Login />
                            </NotRequireAuth>
                        } />

                    <Route
                        path="/owner/*"
                        element={
                            <RequireAuth forMasterAdmin={true}>
                                <MasterAdmin />
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="/admin/*"
                        element={
                            <RequireAuth>
                                <Admin />
                            </RequireAuth>
                        }
                    />
                    {/* <Route
                    path="/a"
                    element={
                        <RequireAuth>
                        <Aadmin />
                    </RequireAuth>
                    }
                /> */}
                    <Route path="*" element={<h1>404 Not Found</h1>} />
                </Routes>
            </Router>
        </>



    );
}

export default IRoutes;