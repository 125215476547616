
import React, {  useState } from 'react';
import { Form, Input, Button } from 'antd';
import { EyeOutlined, UserOutlined } from '@ant-design/icons';
import { API } from '../../../apis/apis';
import { useUIContext } from '../../../context';
import { jwtDecode } from "jwt-decode";
import { errMsgNotify } from '../../../common/util';

const LoginForm = () => {
    const [showPassword, setshowPassword] = useState(false)
    const addOrderUIContext = useUIContext();
    const { setUser,setOwner } = addOrderUIContext
    const { login } = API()
    const onFinish = async (values) => {
        try {
            const res = await login(values)
            const decoded = jwtDecode(res.access);
            res["is_MasterAdmin"]=decoded?.is_superadmin
            if(decoded?.is_superadmin){
                setOwner(res)
                localStorage.setItem("presistedOwner", JSON.stringify(res))
            }else{
                setUser(res)
                localStorage.setItem("presistedUser", JSON.stringify(res))
            }
        } catch (error) {
            errMsgNotify(error.response.data)
            console.log(error);
        }
    };
    return (
        <div className="container  login-section">
            <div className="row justify-content-center align-content-center login-section-inner">
                <div className="col-12">
                    <div className='mb-5'>
                        <h1 className='login-heading'>Login</h1>
                        <span className="head-subtitle">Please <span className='head-subtitle-login'>Login</span> to continue!</span>
                    </div>
                    <Form
                        name="normal_login"
                        className="login-form"
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                    >
                        <Form.Item
                            name="username"
                            rules={[{ required: true, message: 'Please input your Username!' }]}
                        >
                            <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            rules={[{ required: true, message: 'Please input your Password!' }]}
                        >
                            <Input
                                suffix={<EyeOutlined onClick={() => { setshowPassword((prev) => !prev) }} className="site-form-item-icon" />}
                                placeholder="password"
                                type={showPassword ? "text" : "Password"}
                            />
                        </Form.Item>

                        <Form.Item>
                            <Button htmlType="submit" className="login-btn login-form-button">
                                Log in
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default LoginForm;
