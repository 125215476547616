import { SearchOutlined } from '@ant-design/icons'
import { Button, Input, notification } from 'antd'
import React, { useState } from 'react'
import UserForm from '../UserForm/UserForm'
import { API } from '../../../apis/apis'
import { Notify, errMsgNotify } from '../../../common/util'
import { useUIContext } from '../../../context'

const Filter = ({fetchData}) => {
  const api = API()
  const [open, setOpen] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const addOrderUIContext = useUIContext();
  const {setTaskCompltModalMsg,setOpenTaskCompltModal}=addOrderUIContext
  const handleAddUser = (values) => {
    setIsSubmitting(true)

    delete values.confirm
    api.createUser(values).then(() => {
      setOpen(false)
      setIsSubmitting(false)
      fetchData()
      Notify("success", "User Created!")
      setTaskCompltModalMsg("User has been saved successfully")
      setOpenTaskCompltModal(true)
    }).catch((error) => {
      setIsSubmitting(false)
      errMsgNotify(error?.response?.data);
    })
  }
  return (
    <div className='d-flex justify-content-between mb-3'>
      <Input className="w-25" style={{ height: "40px" }}  prefix={<SearchOutlined />} placeholder='Search Users' />
      <Button className="add-user" onClick={() => setOpen(true)}>Add Users</Button>
      {open ? <UserForm open={open} onFinish={handleAddUser} setOpen={setOpen} confirmLoading={isSubmitting} /> : null}
    </div>
  )
}

export default Filter
