import React from 'react'
import LoginForm from './LoginForm'
import loginImg from "../../../assets/img/login.svg"

const Login = () => {
    return (
        <div class="container-xl">
            <div class="row">
                <div class="col-8">
                    <div style={{ height: "75vh" }}>
                        <img alt='img' className='login-img' src={loginImg} />
                    </div>
                </div>
                <div class="col-4">
                    <LoginForm />
                </div>
            </div>
        </div>

    )
}

export default Login
