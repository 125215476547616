import { Route, Routes } from 'react-router-dom';
import SideBar from '../../components/sideBar/SideBar';
import UserListing from './UserListing/UserListing';



function MasterAdmin() {
 

  return (
    <div>
      <SideBar />
      <div className='main-content'>
        <Routes>
          <Route path="/Users" element={<UserListing />} />
          <Route path="*" element={<h1>Page not found</h1>} />
        </Routes>
      </div>
    </div>
  );
}


export default MasterAdmin
