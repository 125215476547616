import { Space, Spin, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { API } from '../../../apis/apis';
import { Notify, errMsgNotify } from '../../../common/util';
import UserForm from '../UserForm/UserForm';
import { useUIContext } from '../../../context';
import TaskCompletion from '../../../components/messsageModal/TaskCompletion';
import ConfirmModal from '../../../components/messsageModal/ConfirmModal';


const Listing = ({ tableData, fetchData }) => {
  // 
  const api = API();
  const [open, setOpen] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [openConfirmModal, setOpenConfirmModal] = useState(false)
  const [editWith, setEditWith] = useState(undefined)

  const addOrderUIContext = useUIContext();
  const { setTaskCompltModalMsg, setOpenTaskCompltModal, TaskCompltModalMsg } = addOrderUIContext

  const handleEditUser = (values) => {
    setIsSubmitting(true)
    delete values.confirm
    api.editUser(tableData?.[editWith].id, values).then(() => {
      setTaskCompltModalMsg("You have updated user !")
      setOpenTaskCompltModal(true)
      setEditWith(undefined)
      fetchData()
      setOpen(false)
      setIsSubmitting(false)
      Notify("success", "User Updated!")
      setEditWith(undefined)
    }).catch((error) => {
      setIsSubmitting(false)
      errMsgNotify(error?.response?.data);
    })
  }

  const deleteUser = () => {
    api.delUser(tableData?.[editWith].id).then(()=>{
      setTaskCompltModalMsg(`You have Deleted ${tableData?.[editWith].username} `)
      setOpenTaskCompltModal(true)
      setOpenConfirmModal(false)
      setEditWith(undefined)
      fetchData()
    }).catch((error) => {
      setIsSubmitting(false)
      errMsgNotify(error?.response?.data);
    })
  }



  const columns = [
    {
      title: 'Name',
      dataIndex: 'username',
      key: 'username',
      render: (text) => <a>{text}</a>,
    },
    // {
    //     title: 'Admin Key',
    //     dataIndex: 'admin_key',
    //     key: 'admin_key',
    // },
    {
      title: 'Status',
      dataIndex: 'is_active',
      key: 'is_active',
      render: (text) => <> {text ? <div className='active-status'>Active</div> : <div className='inactive-status'>Deactiveted</div>}</>,
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record, idx) => (
        <Space size="middle">
          <a onClick={() => {
            setEditWith(idx);
            setOpen(true)
          }}>Edit</a>
          <a onClick={() => {
            setEditWith(idx);
            setTaskCompltModalMsg('Are you sure you would like to delete?')
            setOpenConfirmModal(true)
          }}>Delete</a>
        </Space>
      ),
    },
  ]

  return (
    <div>
      <Table columns={columns} dataSource={tableData} />
      {open ? <UserForm editWith={tableData?.[editWith] || {}} open={open} onFinish={handleEditUser} setOpen={(state) => {
        setOpen(state);
        setEditWith(undefined)
      }} confirmLoading={isSubmitting} /> : null}
      {openConfirmModal ? <ConfirmModal msg={TaskCompltModalMsg} onOk={()=>{deleteUser()}} onClose={() => setOpenConfirmModal(false)} /> : null}
    </div>
  )
}

export default Listing
