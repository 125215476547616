import { notification } from "antd";

export const toAbsoluteUrl = (pathname) => process.env.PUBLIC_URL + pathname;
export const Notify = (type, msg) => notification[type]({ message: msg })

export const  searchAndSort=(query,data)=> {
    return data
      .filter(object => {
        return (object.Username && object.Username.toLowerCase().includes(query.toLowerCase())) ||
               (object.first_name && object.first_name.toLowerCase().includes(query.toLowerCase()));
      })
  }
export const errMsgNotify = (response) => {
    if (!response || typeof response !== 'object' || response?.map) {
        return
    }
    Object.keys(response).map((key) => {

       const messages = response[key]
       if(typeof messages ==="string") {
        Notify("error", messages)
        return
       }
        messages.map((message) => {
            Notify("error", message)
        })
    })

}
export const isEmptyObject=(obj)=> {
    return Object.keys(obj).length === 0;
  }
export const clearLocalToken=(User)=>localStorage.setItem([User],"")

export const checkOwner=()=>document.location.href.includes("owner")


