import axios, { CancelTokenSource } from "axios";
import { useUIContext } from "../context";
import { checkOwner, clearLocalToken } from "../common/util";

const Config = process.env.REACT_APP_BASE_URL || document.location.origin;

export function useBaseService() {
    const cancelTokenSource = axios.CancelToken.source();
    const { user ,owner,setOwner,setUser} = useUIContext()
    let access=user.access
    if(checkOwner()){
        access=owner.access
    }
     // Get the authentication token from your context

    axios.interceptors.response.use(
        (response) => {
            // You can handle successful responses here if needed.
            return response;
        },
        (error) => {
            if (error.response && error.response.status === 401) {
                // Handle 401 error here, e.g., log the user out or redirect to a login page.
                if(checkOwner()){
                    setOwner((prev)=>({access:""}))
                    clearLocalToken("presistedOwner")
                }else{
                    clearLocalToken("presistedUser")
                    setUser((prev)=>({access:""}))
                }
                
                // You can perform a logout action or redirect to a login page here.
            }
            // You can handle other errors as needed.
            return Promise.reject(error);
        }
    );
    const get = async (url, params) => {
        try {
            const response = await axios.get(`${Config}/${url}`, {
                params,
                headers: {
                    Authorization: `Bearer ${access}` // Set the authentication token in the header
                },
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    };
    const postLogin = async (url, body, options = {}) => {
        try {
            const response = await axios.post(`${Config}/${url}`, body, {
                ...options,
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    };
    const post = async (url, body, options = {}) => {
        try {
            const response = await axios.post(`${Config}/${url}`, body, {
                ...options,
                headers: {
                    Authorization: `Bearer ${access}` // Set the authentication token in the header
                },
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    };
    const put = async (url, body, params) => {
        try {
            const response = await axios.put(`${Config}/${url}`, body, {
                ...params,
                cancelToken: cancelTokenSource.token,
                headers: {
                    Authorization: `Bearer ${access}` // Set the authentication token in the header
                },
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    };
    const patch = async (url, body, params) => {
        try {
            const response = await axios.patch(`${Config}/${url}`, body, {
                ...params,
                cancelToken: cancelTokenSource.token,
                headers: {
                    Authorization: `Bearer ${access}` // Set the authentication token in the header
                },
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    };

    const del = async (url, id, data) => {
        try {
            const response = await axios.delete(`${Config}/${url}${id}/`, {
                headers: {
                    Authorization: `Bearer ${access}` // Set the authentication token in the header
                },
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    };

    const cancel = () => {
        cancelTokenSource.cancel("Explicitly cancelled HTTP request");
    };

    return {
        get,patch, post, put, del, cancel,postLogin,
    };
}
