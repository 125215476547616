import React from "react";
import { useNavigate } from "react-router-dom";
import { menuAdmin, menuMaster } from "./utils";
import { useUIContext } from "../../context";
import { checkOwner } from "../../common/util";

export const SideBar = () => {
    const navigate = useNavigate()
    const addOrderUIContext = useUIContext();
    const { user } = addOrderUIContext
    const menu = () => {
        if (user?.is_MasterAdmin || checkOwner()) {
            return menuMaster
        } else {
            return menuAdmin
        }
    }
    return (
        <div className="box">
            {menu()?.map((el) => <>
                <div key={el.name} className="menu-items">
                    <p className="side-bar-menu-item" onClick={() => navigate(el.route)}>{el.name}</p>
                </div>
            </>
            )}

            <div className="rectangle" >

            </div>
        </div>
    );
};


export default SideBar;