import { toAbsoluteUrl } from '../../common/util';
import { Button } from 'antd';
import warningImg from "../../assets/img/warning.png"

const ConfirmModal = ({ msg, onClose,onOk }) => {
    return (
        <div className="modal-backdrop">
            <div className="task-modal">
                <center>  <img width={145} height={145} className="green" alt="Green" src={warningImg} />
                </center>
                <center> <p>{msg}</p>
                </center>
                <center> <Button onClick={onOk}>Yes</Button> <Button onClick={onClose}>No</Button>
                </center></div>
        </div>
    );
};

export default ConfirmModal