import { EllipsisOutlined } from '@ant-design/icons';
import {  Space, Spin, Table, Dropdown, Menu } from 'antd';
import React, { useState } from 'react'
import CreateProjectModal from '../CreateProject';
import { API } from '../../../apis/apis';
import { Notify, errMsgNotify } from '../../../common/util';
import ExportProjectsModal from '../ExportProjectjs/ExportProjects';
import ConfirmModal from '../../../components/messsageModal/ConfirmModal';
import DeleteProjectData from '../DeleteProjectData/DeleteProjectData';
import { VIDEO_QUALITY } from '../../../common/constant';

const Listing = ({ tableData, fetchData }) => {
    const api = API()
    const [isExportModalVisible, setIsExportModalVisible] = useState(false);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [open, setOpen] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [valueToOperate, setValueToOperate] = useState(undefined);
    const [openConfirmModal, setOpenConfirmModal] = useState(false)
    

    const handleDelete = (val) => {
        setOpenConfirmModal(true)
        setValueToOperate(val)
    }
    const handleExportAction = (record) => {
        setValueToOperate(record)
        setIsExportModalVisible(true)
    }
    const handleDeleteAction = (record) => {
        setValueToOperate(record)
        setIsDeleteModalVisible(true)
    }
    const handleEditAction = (val) => {
        setOpen(true)
        setValueToOperate(val)
    }
    const handleEdit = (payload) => {
        setIsSubmitting(true)
        const { name, images, deleted,video_quality } = payload
        const formData = new FormData();
        formData.append("name", name);
        formData.append("video_quality", video_quality);
        if (images) {
            images.forEach(file => {
                if (file.originFileObj) {
                    formData.append('images', file?.originFileObj);
                }
            });
        }
        async function deleteImages(deleted) {
            if (deleted?.length) {
                for (const el of deleted) {
                    const image_url = "/api" + el.url.split("/api")[1];
                    try {
                        await api.delImage(valueToOperate.id, { image_url });

                    } catch (error) {
                        errMsgNotify(error.response.data)
                    }
                }
            }
        }
        const editProject = async () => {
            try {
                await api.editProject(valueToOperate.id, formData)
                Notify("success", "Project updated!")
                fetchData()
                setOpen(false)
                setValueToOperate(undefined)
                setIsSubmitting(false)
                deleteImages(deleted)
            } catch (error) {
                setIsSubmitting(false)
                errMsgNotify(error.response.data)
            }
        }
        editProject()
    }
    const menu = (record) => (
        <Menu>
            <Menu.Item key="Edit" onClick={() => handleEditAction(record)}>Edit</Menu.Item>
            <Menu.Item onClick={() => { handleDelete(record) }} key="Delete">Delete</Menu.Item>
            <Menu.Item key="Export Project's Media" onClick={() => handleExportAction(record)}>Export Project's Media</Menu.Item>
            <Menu.Item key="Delete Project's Media" onClick={() => handleDeleteAction(record)}>Delete Project's Media</Menu.Item>
            
        </Menu>
    );
    const closeConfrimModal = () => { setOpenConfirmModal(false); setValueToOperate(undefined) }

    const deleteProject = async () => {
        try {
            await api.deleteProject(valueToOperate.id)
            closeConfrimModal()
            Notify("success", "Project Deleted")
            fetchData()
        } catch (error) {
            errMsgNotify(error.response.data)
        }

    }

    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Project Name',
            dataIndex: 'name',
            key: 'name',
            render: (text) => <a>{text}</a>,
        },
        {
            title: 'Video Quality',
            dataIndex: 'video_quality',
            key: 'video_quality',
            render:(quality)=><a>{VIDEO_QUALITY[quality]}</a>
        },
        // {
        //     title: 'Date Created',
        //     dataIndex: 'created',
        //     key: 'created',
        // },
        {
            title: 'No of Participants',
            key: 'number_of_participants',
            dataIndex: 'number_of_participants',
        },
        {
            title: 'No of Photos',
            key: 'image_urls',
            dataIndex: 'image_urls',
            render: (image_urls) => image_urls?.length||0,
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Dropdown overlay={menu(record)} trigger={['click']}>
                    <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                        <EllipsisOutlined />
                    </a>
                </Dropdown>
            ),
        },
    ];
    return (
        <div>
            {tableData ?
                <Table columns={columns} dataSource={tableData} />
                :
                <Space size="middle">
                    <Spin size="large" />
                </Space>
            }
            {open ? <CreateProjectModal isSubmitting={isSubmitting} open={open} forEdit={valueToOperate} onCreate={handleEdit} onCancel={() => { setOpen(false); setValueToOperate(undefined) }} />  : null}
            {isExportModalVisible ? <ExportProjectsModal isExportModalVisible={isExportModalVisible} selectedProject={valueToOperate} onCancel={() => { setIsExportModalVisible(false); setValueToOperate(undefined) }} /> : null}
            {isDeleteModalVisible ? <DeleteProjectData isDeleteModalVisible={isDeleteModalVisible} selectedProject={valueToOperate} onCancel={() => { setIsDeleteModalVisible(false); setValueToOperate(undefined) }} /> : null}
            {openConfirmModal ? <ConfirmModal msg={"Are you sure you would like to delete?"} onOk={() => { deleteProject() }} onClose={closeConfrimModal} /> : null}
        </div>
    )
}

export default Listing
