import React from 'react';
import { toAbsoluteUrl } from '../../common/util';
import { Button } from 'antd';
import tickImg from "../../assets/img/tick.png"

const TaskCompletion = ({ msg, onClose }) => {
    return (
        <div className="modal-backdrop">
            <div className="task-modal">
                <center>  <img width={145} height={145} className="green" alt="Green" src={tickImg} />
                </center>
                <center> <p>{msg}</p>
                </center>
                <center> <Button onClick={onClose}>OK, got it</Button>
                </center></div>
        </div>
    );
};



export default TaskCompletion
